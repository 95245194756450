.row_flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.column_flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.subpage{
    composes: column_flex;
    margin-bottom: 50px;
    width: calc(100vw - 300px);
    margin-left: 300px;
    justify-content: flex-start;
}

.subpage_full_width{
    composes: column_flex;
    width: 100%;
}

.left_fixed{
    z-index: 1;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 300px;
    background-color: #092635;
    left: 0;
    transition: left 500ms ease-in-out;
}

.left_hide{
    composes: left_fixed;
    left: -300px;
}

.large_container{
    composes: row_flex;
    margin-top: 30px;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    height: auto;
}

.large_container_flex_start{
    composes: large_container;
    justify-content: flex-start;
}

.large_container_col{
    composes: column_flex;
    margin-top: 30px;
    padding: 8%;
    justify-content: space-evenly;
    gap: 30px;
}
.large_container_col_left{
    width: 100%;
    composes: column_flex;
    padding: 0 8%;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
}

.medium_container_long{
    composes: column_flex;
    justify-content: flex-start;
    gap: 40px;
    width: 50%;
}

.medium_container_short{
    composes: column_flex;
    justify-content: flex-start;
    width: 30%;
}

.medium_container_col{
    composes: column_flex;
    gap: 30px;
    justify-content: flex-start;
}

.ul_container{
    composes: column_flex;
    width: 100%;
    list-style: none;
    padding: 15px;
}

.cover_container{
    width: 100%;
    height: 30vh;
    overflow: hidden;
}

.card{
    composes: column_flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45%;
    height: 100%;
    padding: 25px;
    gap: 10px;
    margin: 2.5%;
    background-color: #F5F5F5;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}

.card_full_width{
    composes: card;
    margin: 20px;
    width: 90%;
}

.item{
    composes: row_flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    font-size: 0.8rem;
}