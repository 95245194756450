.light_medium_button {
    padding: 20px 0;
    border: white 3px solid;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    cursor: pointer;
    margin: 10px 0;
  }

.dark_medium_button {
    composes: light_medium_button;
    border: #092635 3px solid;
    color: #092635;
  }
  
.circle{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
    z-index: 1;
    margin: 20px;
  }

.circle_fixed{
    composes: circle;
    position: fixed;
}

.circle_buttom_right{
    composes: circle_fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
}

.tag{
    padding: 5px 10px;
    border-radius: 10px;
    width: fit-content;
    background-color: #3C3633;
    color: white;
    font-size: 0.6rem;
    font-weight: 400;
    margin: 5px;
}