* {
    font-family:'Avenir Next','Lucida Sans', monospace,'Times new roman';
    box-sizing: border-box;
    margin:0;
    padding:0;
    text-decoration: none;
    font-weight: 400;
}

h1 {
    color:#4D4D4D;
    font-size:calc(2rem+0.2vw);
    margin-top: 5%;
}

h2 {
    color:#427D9D;
    font-size: calc(1.8rem+0.1vw);
    margin-bottom: 1rem;
}

h3 {
    color: #4D4D4D;
    font-size: calc(1.5rem+0.1vw);
    font-weight: 600;
    margin-bottom: 0.5rem;
}
h5{
    color: #4D4D4D;
    font-size: calc(1.4rem+0.1vw);
    margin-bottom: 1rem;
}

a {
    color: black;
    font-size: calc(1.2rem+0.1vw);
}

p {
    color:black;
    font-size: calc(1.2rem+0.1vw);
    margin-bottom: 0.5rem;
}